document.documentElement.className = document.documentElement.className.replace('no-js', 'js');

(function($) {

    $('.sidenav').sidenav();

    $('.collapsible').collapsible({
        accordion: false // A setting that changes the collapsible behavior to expandable instead of the default accordion style
    });

    $('select:not(.browser-default)').formSelect();

    $('.datepicker').datepicker({
        format: 'yyyy-mm-dd'
    });

    $('input[type="date"].datepicker').on('click', function (e) {
        e.preventDefault();
    });

    var $toggleField = $('.js-toggle-form-field');
    if ($toggleField.length > 0) {
        function toggleFormField($field) {
            var value = $field.val(),
                targetNo = $field.data('target-no'),
                targetYes = $field.data('target-yes');
            if ($(targetNo).length > 0 && $(targetYes).length > 0) {
                if (1 === parseInt(value)) {
                    $(targetNo).closest('.row').hide();
                    $(targetYes).closest('.row').show();
                } else {
                    $(targetNo).closest('.row').show();
                    $(targetYes).closest('.row').hide();
                }
            }
        }
        $toggleField.on('change', function () {
            toggleFormField($(this));
        });
        $(window).on('load', function () {
            $toggleField.trigger('change');
        });
    }

    var $siteSelect = $('select.js-update-admin-user');
    if ($siteSelect.length > 0) {
        $siteSelect.on('change', function() {
            var $form = $(this).closest('form'),
                $tokenInput = $form.find('admin_user__token'),
                data = {};

            data[$siteSelect.attr('name')] = $siteSelect.val();
            data[$tokenInput.attr('name')] = $tokenInput.val();

            $.ajax({
                url  : $form.attr('action'),
                type : $form.attr('method'),
                data : data,
                success: function(html) {
                    $('#admin_user_brand').closest('.select-wrapper').replaceWith(
                        $(html).find('#admin_user_brand')
                    );
                    $('#admin_user_brand').formSelect();

                    $('#admin_user_actions').closest('.select-wrapper').replaceWith(
                        $(html).find('#admin_user_actions')
                    );
                    $('#admin_user_actions').formSelect();
                }
            });
        });
    }

    var $userSelect = $('select.js-update-careers');
    if ($userSelect.length > 0) {
        $userSelect.on('change', function() {
            var $form = $(this).closest('form'),
                $tokenInput = $form.find('careers__token'),
                data = {};

            data[$userSelect.attr('name')] = $userSelect.val();
            data[$tokenInput.attr('name')] = $tokenInput.val();

            $.ajax({
                url  : $form.attr('action'),
                type : $form.attr('method'),
                data : data,
                success: function(html) {
                    $('#careers_level').closest('.select-wrapper').replaceWith(
                        $(html).find('#careers_level')
                    );
                    $('#careers_level').formSelect();

                    $('#careers_function').closest('.select-wrapper').replaceWith(
                        $(html).find('#careers_function')
                    );
                    $('#careers_function').formSelect();

                    $('#careers_city').closest('.select-wrapper').replaceWith(
                        $(html).find('#careers_city')
                    );
                    $('#careers_city').formSelect();
                }
            });
        });
    }

})(jQuery);
